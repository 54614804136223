export const languages = [
  {
    value: 'en',
    label: 'English',
  },
  {
    value: 'ar',
    label: 'العربية',
  }
]

export const countries = [
  // {
  //   value: 'ae',
  //   icon: '/flag/united-arab-emirates.svg',
  //   localizable: true,
  //   public: true,
  //   sorter: 'randb_UAE_stock_sorter',
  //   excludeAttribute: 'exclude_in_ae',
  //   code: '+971',
  //   link: '/en-ae/',
  //   en: {
  //     link: '/en-ae/',
  //     currency: 'AED',
  //     label: 'UAE',
  //     city: 'Dubai'
  //   },
  //   ar: {
  //     link: '/ar-ae/',
  //     currency: 'د.إ',
  //     label: 'الإمارات',
  //     city: 'دبي'
  //   }
  // },
  {
    value: 'qa',
    icon: '/flag/qatar.svg',
    localizable: true,
    public: false,
    sorter: 'randb_QATAR_stock_sorter',
    excludeAttribute: 'exclude_in_qa',
    code: '+974',
    link: 'https://www.clarks.qa/en-qa/',
    defaultLocale: 'ar-qa',
    en: {
      link: 'https://www.clarks.qa/en-qa/',
      currency: 'QAR',
      label: 'QATAR',
      city: 'Doha'
    },
    ar: {
      link: 'https://www.clarks.qa/ar-qa/',
      currency: 'ر.ق',
      label: 'دولة قطر',
      city: 'الدوحة'
    }
  },
  {
    value: 'sa',
    icon: '/flag/saudi-arabia.svg',
    localizable: true,
    public: true,
    sorter: 'randb_KSA_stock_sorter',
    excludeAttribute: 'exclude_in_sa',
    code: '+966',
    link: 'https://www.clarks.sa/ar-sa/',
    defaultLocale: 'ar-sa',
    en: {
      link: 'https://www.clarks.sa/en-sa/',
      currency: 'SAR',
      label: 'KSA',
      city: 'Riyadh'
    },
    ar: {
      link: 'https://www.clarks.sa/ar-sa/',
      currency: 'ر.س',
      label: 'السعودية',
      city: 'الرياض'
    }
  },
  {
    value: 'om',
    apiLabel: 'OMAN',
    icon: '/flag/oman.svg',
    localizable: true,
    public: false,
    sorter: 'randb_OMAN_stock_sorter',
    excludeAttribute: 'exclude_in_om',
    code: '+968',
    link: 'https://www.clarks.om/ar-om/',
    defaultLocale: 'ar-om',
    en: {
      link: 'https://www.clarks.om/en-om/',
      currency: 'OMR',
      label: 'OMAN',
      city: 'Muscat'
    },
    ar: {
      link: 'https://www.clarks.om/ar-om/',
      currency: 'ر.ع',
      label: 'سلطنة عمان',
      city: 'مسقط'
    }
  },
  // {
  //   value: 'kw',
  //   icon: '/flag/kuwait.svg',
  //   localizable: true,
  //   public: false,
  //   sorter: 'randb_KUWAIT_stock_sorter',
  //   excludeAttribute: 'exclude_in_kw',
  //   code: '+965',
  //   link: 'https://www.ar-kw.randbfashion.com/',
  //   en: {
  //     link: 'https://www.en-kw.randbfashion.com/',
  //     currency: 'KWD',
  //     label: 'KUWAIT',
  //     city: 'Kuwait City'
  //   },
  //   ar: {
  //     link: 'https://www.ar-kw.randbfashion.com/',
  //     currency: 'د.ك',
  //     label: 'الكويت',
  //     city: 'مدينة الكويت'
  //   }
  // },
  // {
  //   value: 'bh',
  //   apiLabel: 'BAHRAIN',
  //   labelEn: 'BAHRAIN',
  //   labelAr: 'البحرين',
  //   icon: '/flag/bahrain.svg',
  //   localizable: true,
  //   public: false,
  //   sorter: 'randb_BAHRAIN_stock_sorter',
  //   excludeAttribute: 'exclude_in_bh',
  //   code: '+973',
  //   link: 'https://www.ar-bh.randbfashion.com/',
  //   en: {
  //     link: 'https://www.en-bh.randbfashion.com/',
  //     currency: 'BHD',
  //     label: 'BAHRAIN',
  //     city: 'Manama'
  //   },
  //   ar: {
  //     link: 'https://www.ar-bh.randbfashion.com/',
  //     currency: 'د.ب',
  //     label: 'البحرين',
  //     city: 'المنامة'
  //   }
  // }
]

export const countrySorterMap = {
  ae: 'randb_UAE_stock_sorter',
  qa: 'randb_QATAR_stock_sorter',
  sa: 'randb_KSA_stock_sorter',
  om: 'randb_OMAN_stock_sorter',
  kw: 'randb_KUWAIT_stock_sorter',
  bh: 'randb_BAHRAIN_stock_sorter'
}

export const countryCurrencyMap = {
  ae: 'aed',
  qa: 'qar',
  sa: 'sar',
  om: 'omr',
  kw: 'kwd',
  bh: 'bhd'
}

export const countryExcludeAttributeMap = {
  // {"products.attributes_exclude_in_ae":[true]}
  ae: 'eyJwcm9kdWN0cy5hdHRyaWJ1dGVzX2V4Y2x1ZGVfaW5fYWUiOlt0cnVlXX0=',
  // {"products.attributes_exclude_in_qa":[true]}
  qa: 'eyJwcm9kdWN0cy5hdHRyaWJ1dGVzX2V4Y2x1ZGVfaW5fcWEiOlt0cnVlXX0=',
  // {"products.attributes_exclude_in_sa":[true]}
  sa: 'eyJwcm9kdWN0cy5hdHRyaWJ1dGVzX2V4Y2x1ZGVfaW5fc2EiOlt0cnVlXX0=',
  // {"products.attributes_exclude_in_om":[true]}
  om: 'eyJwcm9kdWN0cy5hdHRyaWJ1dGVzX2V4Y2x1ZGVfaW5fb20iOlt0cnVlXX0=',
  // {"products.attributes_exclude_in_kw":[true]}
  kw: 'eyJwcm9kdWN0cy5hdHRyaWJ1dGVzX2V4Y2x1ZGVfaW5fa3ciOlt0cnVlXX0=',
  // {"products.attributes_exclude_in_bh":[true]}
  bh: 'eyJwcm9kdWN0cy5hdHRyaWJ1dGVzX2V4Y2x1ZGVfaW5fYmgiOlt0cnVlXX0='
}
