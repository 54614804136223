enum GENERAL_ROUTES {
  HOME = '/',
  BASKET = '/baskets/basket',
  LIST = '/list'
}

enum AUTH_ROUTES {
  AUTH = '/users/auth',
  FORGOT_PASSWORD = '/users/password/reset',
  EMAIL_SET_PRIMARY = '/users/email-set-primary/.+',
  CONFIRM_EMAIL = '/users/registration/account-confirm-email/.+',
  TERMS_OF_USE = '/terms-of-use',
  PRIVACY_POLICY = '/privacy-policy',
  FORGOT_PASSWORD2 = '/users/reset/.+'
}

enum ACCOUNT_ROUTES {
  ACCOUNT = '/account',
  ACCOUNT_ADDRESS = '/account/address',
  ACCOUNT_CHANGE_EMAIL = '/account/change-email',
  ACCOUNT_CHANGE_PASSWORD = '/account/change-password',
  ACCOUNT_CONTACT = '/account/contact',
  ACCOUNT_COUPONS = '/account/coupons',
  ACCOUNT_FAQ = '/account/faq',
  ACCOUNT_ORDERS = '/account/orders',
  ACCOUNT_PROFILE = '/account/profile',
  ACCOUNT_WISHLIST = '/account/favourite-products/',
  ACCOUNT_EMAIL_PREFERENCES = '/account/email-preferences',
  ANONYMOUS_TRACKING = '/anonymous-tracking',
  ACCOUNT_STORE_CREDIT = '/account/store-credit/'
}

enum ORDER_ROUTES {
  CHECKOUT = '/orders/checkout',
  CHECKOUT_COMPLETED = '/orders/completed',
  STORE_LOCATOR = '/address/stores',
  HELP = '/customer-services/',
  TRACK_ORDER = 'https://clarks.clickpost.ai/'
}

enum FLATPAGE_ROUTES {
  CONTACT_US = '/contact-us',
  SHIPPING_DELIVERY = '/shipping-and-delivery',
  FAQS = '/customer-service/faqs',
}

enum PRODUCT_ROUTES { }

enum LANDING_PAGE_ROUTES {
  WOMENS = '/womens',
  MENS = '/mens',
  KIDS = '/kids',
  ACCESSORIES = '/accessories'
}

export const ROUTES = {
  ...GENERAL_ROUTES,
  ...AUTH_ROUTES,
  ...ACCOUNT_ROUTES,
  ...ORDER_ROUTES,
  ...FLATPAGE_ROUTES,
  ...PRODUCT_ROUTES,
  ...LANDING_PAGE_ROUTES
};
